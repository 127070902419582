import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GroupAddIcon from '@mui/icons-material/GroupAdd'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import { Box, Toolbar } from '@mui/material'

import StyledIconButton from '@components/StyledIconButton'
import StyledTable from '@components/StyledTable'
import FilterKpi from '@components/toolbar/FilterKpi'
import type { Activiteit as ActiviteitType } from '@models/Activiteit'
import type { Entity } from '@models/Entity'
import { futureDay, isToday, pastDay } from '@util/date-util'

import type { Project } from '../../../aansluiting/project/types'
import { getUser } from '../../../auth/state'
import type { GroupedOption } from '../../../projectdossier/types'
import {
  closeActiviteitenDialog,
  getActiviteitenDialogData,
  isActiviteitenDialogOpen,
  openActiviteitenDialog,
} from '../state'

import { Activiteit } from './Activiteit'
import { ActiviteitenDialog } from './ActiviteitenDialog'

const filterActiviteiten = (activiteiten, statusFilter, expiredFilter) => {
  let filteredActiviteiten = activiteiten

  if (!statusFilter) {
    filteredActiviteiten = filteredActiviteiten.filter((activiteit) => {
      const isCompleted = activiteit.completed?.date && true

      return !isCompleted
    })
  }

  let filter

  switch (expiredFilter) {
    // If green, show activities that aren't expired yet
    case 'green':
      filter = (activiteit) =>
        futureDay(activiteit.dueDate) && !activiteit.completed
      break
    // Not implemented yet
    case 'amber':
      filter = (activiteit) =>
        isToday(activiteit.dueDate) && !activiteit.completed
      break
    // Show only expired activities
    case 'red':
      filter = (activiteit) =>
        pastDay(activiteit.dueDate) && !activiteit.completed
      break
    case 'noduedate':
      filter = (activiteit) => !activiteit.dueDate
      break
    // All others, don't filter on duedate
    default:
      filter = () => true
      break
  }

  filteredActiviteiten = filteredActiviteiten.filter(filter)

  return filteredActiviteiten
}

const sortActiviteiten = (activiteiten) => {
  // Group by has dueDate
  const { noDueDate, hasDueDate } = activiteiten.reduce(
    (acc, activiteit) => {
      activiteit.dueDate
        ? acc.hasDueDate.push(activiteit)
        : acc.noDueDate.push(activiteit)

      return acc
    },
    { noDueDate: [], hasDueDate: [] },
  )

  // Sort activiteiten with dueDate by dueDate and append activiteiten with no dueDate
  // dueDates in chronological order, dates arriving first at the top, dates arriving last at the bottom
  // and no dueDate activities at the very end
  const sortedByDueDate: any = hasDueDate.sort(
    (a, b) => Number(new Date(a.dueDate)) - Number(new Date(b.dueDate)),
  )

  return [...sortedByDueDate, ...noDueDate]
}

const filterTooltips = {
  red: 'Geef alleen verlopen activiteiten weer',
  amber: 'Geef alle activiteiten die vandaag verlopen weer',
  green: 'Geef alleen nog niet verlopen activiteiten weer',
  grey: 'Geef alle activiteiten weer',
  noduedate: 'Geef alleen de activiteiten zonder opleverdatum weer',
}

const headerCells = [
  { id: 'expired', numeric: false, label: '', fixed: 25 },
  { id: 'type', numeric: false, label: 'Type activiteit', fixed: 125 },
  { id: 'assignedTo', numeric: false, label: 'Toegewezen aan', fixed: 250 },
  { id: 'data.text', numeric: false, label: 'Opmerking' },
  { id: 'dueDate', numeric: false, label: 'Opleverdatum', fixed: 100 },
  { id: 'created', numeric: false, label: 'Aangemaakt', fixed: 100 },
  { id: 'deelnemer', numeric: false, label: 'Aangemaakt door', fixed: 150 },
  { id: 'status', numeric: false, label: 'Status', fixed: 100 },
  { id: 'actions', numeric: false, label: 'Acties', fixed: 120 },
]

interface Props {
  activiteiten: ActiviteitType[]
  entity: Entity
  entityProps?: Partial<Project>
  assignees: GroupedOption[]
}

export const Activiteiten: FC<Props> = ({
  activiteiten,
  entity,
  entityProps,
  assignees,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { canCreateActiviteit } = user
  const isDialogOpen = useSelector(isActiviteitenDialogOpen)
  const dialogData = useSelector(getActiviteitenDialogData)
  const [displayAllStatusses, setDisplayAllStatusses] = useState(false)
  const [displayOnlyExpired, setDisplayOnlyExpired] = useState('')
  const [activiteitenToDisplay, setActiviteitenToDisplay] =
    useState(activiteiten)

  useEffect(() => {
    const filtered = filterActiviteiten(
      activiteiten,
      displayAllStatusses,
      displayOnlyExpired,
    )

    setActiviteitenToDisplay(sortActiviteiten(filtered))
  }, [displayAllStatusses, displayOnlyExpired, activiteiten])

  const handleClickActiviteit = ({ key, isEdit, activiteit }: any) => {
    dispatch(
      openActiviteitenDialog({
        key,
        isEdit,
        activiteit,
        entity,
        entityProps,
        assignees,
        type: 'create',
      }),
    )
  }

  const handleClickFinish = ({ key, type, activiteit }) => {
    dispatch(openActiviteitenDialog({ key, type, activiteit }))
  }

  const handleClickCancel = ({ key, type, activiteit }) => {
    dispatch(openActiviteitenDialog({ key, type, activiteit }))
  }

  const handleClickDisplayAllStatusses = () =>
    setDisplayAllStatusses(!displayAllStatusses)
  const handleClickFilterExpired = (data) => setDisplayOnlyExpired(data.kpi)

  const onCloseDialog = () => dispatch(closeActiviteitenDialog())

  const isPossibleAssignee = assignees.some((a) => a.label === user.organisatie)

  return (
    <>
      <Toolbar
        sx={{
          backgroundColor: '#F7F7F7',
          borderBottom: '1px solid #E0E0E0',
          justifyContent: 'flex-end',
        }}
      >
        {canCreateActiviteit && isPossibleAssignee && (
          <>
            <StyledIconButton
              title="Nieuwe activiteit"
              onClick={() =>
                handleClickActiviteit({ key: 'new', isEdit: false })
              }
              icon={<GroupAddIcon />}
            />
          </>
        )}
        <StyledIconButton
          title={
            displayAllStatusses
              ? 'Klik om alleen open activiteiten te tonen'
              : 'Klik om ook afgehandelde activiteiten te tonen'
          }
          onClick={handleClickDisplayAllStatusses}
          icon={
            displayAllStatusses ? <LibraryBooksIcon /> : <LibraryAddCheckIcon />
          }
        />
        <Box>
          <FilterKpi
            onFilterChange={handleClickFilterExpired}
            iconClass={displayOnlyExpired}
            isActiviteiten="true"
            tooltips={filterTooltips}
            {...({} as any)}
          />
        </Box>
      </Toolbar>
      <StyledTable variant="details" headerCells={headerCells} {...({} as any)}>
        {activiteitenToDisplay.map((activiteit) => {
          return (
            <Activiteit
              key={activiteit._id}
              activiteit={activiteit}
              user={user}
              handleClickFinish={handleClickFinish}
              handleClickEdit={() =>
                handleClickActiviteit({
                  activiteit,
                  key: activiteit._id,
                  isEdit: true,
                })
              }
              handleClickCancel={handleClickCancel}
            />
          )
        })}
      </StyledTable>
      <ActiviteitenDialog
        open={isDialogOpen}
        data={dialogData}
        onClose={onCloseDialog}
      />
    </>
  )
}
