import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'

import { bijlageStatusTranslations } from '@util/hoofdleiding-util'

import { Tile } from '../../../aansluiting/dashboard/components/werklijsten/Tile'
import type { RootState } from '../../../store'
import { initialState as initialStateAssignedBijlagen } from '../../bijlagen/state'
import { fetchWerklijsten, getWerklijsten } from '../state'
import type { Filters, Werklijst } from '../types'

export interface Props {
  filters: Filters
  onClickTile: (query: any, url: any) => void
}

export const TeBeoordelen: FC<Props> = ({ filters, onClickTile }) => {
  const dispatch = useDispatch()
  const {
    werklijsten: { reviewBijlagen },
  } = useSelector((state: RootState) => ({
    werklijsten: getWerklijsten(state),
  }))

  const onClickTileBijlagen = (werklijst: Werklijst) => {
    const { label } = werklijst

    const { opleverweekVan, opleverweekTot, ...rest } = filters

    const query = {
      filters: {
        ...initialStateAssignedBijlagen.query.filters,
        ...rest,
        documentsoort: [label],
        status: {
          code: werklijst.code,
          label: bijlageStatusTranslations[werklijst.code],
        },
        assignedTo: filters.organisatie ? [filters.organisatie] : [],
        fromWeek: opleverweekVan || '',
        toWeek: opleverweekTot || '',
      },
      page: initialStateAssignedBijlagen.query.page,
      sort: initialStateAssignedBijlagen.query.sort,
    }

    onClickTile(query, '/hoofdleiding/bijlagen')
  }

  useEffect(() => {
    dispatch(fetchWerklijsten({ filters }))
  }, [dispatch, filters])

  return (
    <Box sx={{ p: '50px' }}>
      <Grid container spacing={2}>
        {reviewBijlagen.map((werklijst) => (
          <Grid item key={`${werklijst.label}-${werklijst.code}`}>
            <Tile
              werklijst={werklijst}
              onClickTile={onClickTileBijlagen}
              subLabel={bijlageStatusTranslations[werklijst.code]}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
