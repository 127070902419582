import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Box, Paper, Tab, Tabs, Typography } from '@mui/material'

import useLocalStorage from '@hooks/useLocalStorage'

import { getUser } from '../../auth/state'
import type { RootState } from '../../store'
import { storeQuery as storeQueryActiviteiten } from '../activiteiten/state'
import { storeQuery as storeQueryAssignedBijlagen } from '../bijlagen/state'
import { storeQuery as storeQueryHoofdleidingen } from '../projecten/state'

import { Filters } from './components/Filters'
import { Processtappen } from './components/Processtappen'
import { TeBeoordelen } from './components/TeBeoordelen'
import { Werklijsten } from './components/Werklijsten'
import {
  getQuery,
  getSelectedTab,
  initialState,
  storeSelectedTab,
} from './state'

const SELECTED_PADDING = 3

export const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { query, selectedTab } = useSelector((state: RootState) => ({
    user: getUser(state),
    query: getQuery(state),
    selectedTab: getSelectedTab(state),
  }))

  const { item: storedFilters } = useLocalStorage(
    'hoofdleidingDashboard',
    query.filters,
    initialState.query.filters,
  )

  const [localFilters, setLocalFilters] = useState(storedFilters)

  const onClickTile = (query, url) => {
    if (url === '/hoofdleiding/projecten')
      dispatch(storeQueryHoofdleidingen(query))
    if (url === '/hoofdleiding/bijlagen')
      dispatch(storeQueryAssignedBijlagen(query))
    if (url === '/hoofdleiding/activiteiten')
      dispatch(storeQueryActiviteiten(query))

    navigate(url)
  }

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <Typography variant="h2">Dashboard</Typography>
      <Tabs
        centered
        value={selectedTab}
        onChange={(_, tab) => dispatch(storeSelectedTab(tab))}
      >
        <Tab label="Processtappen" value="processtappen" />
        <Tab label="Werklijsten" value="werklijsten" />
        <Tab label="Te beoordelen" value="te_beoordelen" />
      </Tabs>

      <Paper
        sx={{ mb: 2, pb: selectedTab === 'werklijsten' ? SELECTED_PADDING : 0 }}
      >
        <Filters
          filters={localFilters}
          onChange={setLocalFilters}
          initialFilters={initialState.query.filters}
        />

        {selectedTab === 'processtappen' && (
          <Processtappen filters={localFilters} onClickTile={onClickTile} />
        )}
        {selectedTab === 'te_beoordelen' && (
          <TeBeoordelen filters={localFilters} onClickTile={onClickTile} />
        )}
      </Paper>
      {selectedTab === 'werklijsten' && (
        <Werklijsten filters={localFilters} onClickTile={onClickTile} />
      )}
    </Box>
  )
}
