import React, { FC } from 'react'

import { Dialog } from '@mui/material'

import type { OptionsDialogData } from '../../types'
import { Aannemer } from '../dialogs/Aannemer'
import { Disciplines } from '../dialogs/Disciplines'
import { Herplan } from '../dialogs/Herplan'
import { Labels } from '../dialogs/Labels'
import { LinkProject } from '../dialogs/LinkProject'
import { Processtap } from '../dialogs/Processtap'
import { TechnischGereed } from '../dialogs/TechnischGereed'

export interface Props {
  open: boolean
  data: OptionsDialogData
  onClose: () => void
}

export const ProjectOptionsDialog: FC<Props> = ({
  open,
  data,
  onClose,
}: Props) => {
  const { type } = data

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {type === 'labels' && <Labels data={data} handleClose={onClose} />}
      {type === 'herplanning' && <Herplan data={data} handleClose={onClose} />}
      {type === 'hoofdleiding_aannemer' && (
        <Aannemer data={data} handleClose={onClose} />
      )}
      {type === 'hoofdleiding_disciplines' && (
        <Disciplines data={data} handleClose={onClose} />
      )}
      {type === 'link_project' && (
        <LinkProject data={data} handleClose={onClose} />
      )}
      {type === 'hoofdleiding_processtap' && (
        <Processtap data={data} handleClose={onClose} />
      )}
      {type === 'technisch_gereed' && (
        <TechnischGereed data={data} handleClose={onClose} />
      )}
    </Dialog>
  )
}
