import React, { FC, useState } from 'react'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import GradingIcon from '@mui/icons-material/Grading'
import InfoIcon from '@mui/icons-material/Info'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import ExpandRow from '@components/ExpandRow'
import StyledIconButton from '@components/StyledIconButton'
import { useZipper } from '@hooks/useZipper'
import { formatDateTime, formatDateToISOWeek } from '@util/date-util'

import type { AuthenticatedUser } from '../../../auth/types'
import { AssignedBijlageCaption } from '../../../core/components/AssignedBijlageCaption'
import type { AssignedBijlage as AssignedBijlageType, Bijlage } from '../types'

import { AssignedBijlageLog } from './AssignedBijlageLog'
import { BijlagenWithLogRows } from './BijlagenWithLogRows'

const headerCells = [
  { id: 'naam', label: 'Naam', fixed: 500 },
  { id: 'filler', label: '', fixed: 240 },
  { id: 'fille2', label: '', fixed: 240 },
  { id: 'created.date', label: 'Aangemaakt', fixed: 50 },
  { id: 'created.organisatie', label: 'Aangemaakt door', fixed: 150 },
  { id: 'filler3', label: '', fixed: 150 },
  { id: 'opties', label: '', fixed: 180 },
] as const

interface Props {
  assignedBijlage: AssignedBijlageType
  bijlagen: Bijlage[]
  user: AuthenticatedUser | Record<string, any>
  onOpen: (bijlage: Bijlage) => void
  onUpload: (bijlage: AssignedBijlageType) => void
  onCancel: (bijlage: Bijlage | AssignedBijlageType) => void
  onOpleveren: (bijlage: AssignedBijlageType) => void
  onReview: (bijlage: AssignedBijlageType) => void
  onEdit: (bijlage: AssignedBijlageType) => void
  showCancelAssignedBijlage: boolean
  isVerantwoordelijke: boolean
}

export const AssignedBijlage: FC<Props> = ({
  assignedBijlage,
  bijlagen,
  user,
  onOpen,
  onUpload,
  onCancel,
  onOpleveren,
  onReview,
  onEdit,
  showCancelAssignedBijlage,
  isVerantwoordelijke,
}) => {
  const {
    _id,
    documentsoort,
    discipline,
    assignedTo,
    dueWeek,
    completed,
    status,
    created,
    title,
    entity,
    herplanDiff,
  } = assignedBijlage
  const { organisatie } = user
  const isAssigned = status === 'assigned'
  const isOpgeleverd = status === 'done'
  const isUploaded = status === 'uploaded'
  const isCancelled = status === 'cancelled'
  const isWaiting = status === 'waiting'
  const isReview = status === 'review'
  const assignedToOrganisatie = assignedTo === organisatie
  const hasBijlagen = bijlagen.length > 0
  const canEdit = (isAssigned || isUploaded) && !herplanDiff

  const [expandBijlagen, setExpandBijlagen] = useState(false)
  const [toggleLogDrawer, setToggleLogDrawer] = useState(false)

  const handleBijlagenClick = () => {
    setExpandBijlagen(!expandBijlagen)
  }

  const changeLogDrawer = () => setToggleLogDrawer(!toggleLogDrawer)

  let fileName = `${entity.id}-${entity.projecttype}-${assignedBijlage.processtatus}-${documentsoort}`
  if (discipline) {
    fileName = `${fileName}-${discipline}`
  }

  const { loading, save } = useZipper(fileName)

  return (
    <>
      <TableRow
        key={_id}
        sx={{
          backgroundColor: isCancelled ? 'lightgray !important' : '',
          m: 0,
        }}
      >
        <TableCell>
          <>
            {`${title ? `Overig: ${title}` : documentsoort} ${
              discipline || ''
            }`}

            <AssignedBijlageCaption assignedBijlage={assignedBijlage} />
          </>
        </TableCell>

        <TableCell>{assignedTo}</TableCell>

        <TableCell>
          {hasBijlagen ? (
            <ExpandRow
              data-testid="chipBijlagen"
              label={dueWeek}
              expanded={expandBijlagen}
              onDelete={handleBijlagenClick}
              onClick={handleBijlagenClick}
            />
          ) : (
            dueWeek
          )}
        </TableCell>

        <TableCell>{formatDateTime(created.date)}</TableCell>

        <TableCell>
          {created.organisatie}

          {created.email ? (
            <Typography variant="caption">
              <br />
              {created.email}
            </Typography>
          ) : (
            ''
          )}
        </TableCell>

        <TableCell>
          {isOpgeleverd && completed ? (
            <Chip
              label={formatDateToISOWeek(completed.date)}
              variant="outlined"
              sx={{
                borderColor: 'green',
                color: 'green',
                backgroundColor: 'white',
              }}
            />
          ) : (
            ''
          )}
        </TableCell>

        <TableCell>
          {isVerantwoordelijke && canEdit && (
            <StyledIconButton
              title="Bijlage aanpassen"
              icon={<EditIcon />}
              onClick={() => onEdit(assignedBijlage)}
            />
          )}
          {(isVerantwoordelijke || assignedToOrganisatie) &&
            !(isCancelled || isOpgeleverd || isWaiting || isReview) && (
              <StyledIconButton
                title="Bijlagen uploaden"
                onClick={() => onUpload(assignedBijlage)}
                icon={<UploadFileRoundedIcon />}
              />
            )}

          {hasBijlagen && (
            <>
              {bijlagen.length === 1 && (
                <StyledIconButton
                  title="Bijlage openen"
                  icon={<CloudDownloadIcon />}
                  onClick={() => onOpen(bijlagen[0])}
                />
              )}

              {bijlagen.length > 1 &&
                bijlagen.find((bijlage) => bijlage.status !== 'cancelled') &&
                !isCancelled && (
                  <StyledIconButton
                    title="Bijlagen downloaden"
                    icon={
                      loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloudDownloadIcon />
                      )
                    }
                    onClick={() =>
                      save(
                        bijlagen
                          .filter((bijlage) => bijlage.status !== 'cancelled')
                          .map(
                            (bijlage) =>
                              `/rest/${entity.type}/${entity.id}/bijlage/${bijlage._id}`,
                          ),
                      )
                    }
                  />
                )}

              {(isVerantwoordelijke || assignedToOrganisatie) && isUploaded && (
                <StyledIconButton
                  title="Bijlage opleveren"
                  icon={<PlaylistPlayIcon />}
                  onClick={() => onOpleveren(assignedBijlage)}
                />
              )}
              {(isVerantwoordelijke || assignedToOrganisatie) && isReview && (
                <StyledIconButton
                  title="Bijlage beoordelen"
                  icon={<GradingIcon />}
                  onClick={() => onReview(assignedBijlage)}
                />
              )}
            </>
          )}

          {showCancelAssignedBijlage &&
            !isReview &&
            (isVerantwoordelijke || assignedToOrganisatie) &&
            !isCancelled && (
              <StyledIconButton
                title="Bijlage annuleren"
                icon={<DeleteIcon />}
                onClick={() => onCancel(assignedBijlage)}
              />
            )}

          <StyledIconButton
            title="Bijlage log"
            onClick={changeLogDrawer}
            icon={<InfoIcon />}
          />
        </TableCell>

        {toggleLogDrawer && (
          <AssignedBijlageLog
            open={toggleLogDrawer}
            onClose={changeLogDrawer}
            assignedBijlage={assignedBijlage}
          />
        )}
      </TableRow>

      {hasBijlagen && expandBijlagen ? (
        <TableRow>
          <TableCell colSpan={12} padding="none">
            <Table
              sx={{
                backgroundColor: '#f7f7f7',
                filter: 'brightness(0.95)',
                outline: '1px dashed gray',
                outlineOffset: '-1px',
              }}
            >
              <TableHead>
                <TableRow>
                  {headerCells.map((headerCell) => (
                    <TableCell
                      key={headerCell.id}
                      sx={{
                        width: headerCell.fixed,
                      }}
                    >
                      {headerCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <BijlagenWithLogRows
                  assignedBijlage={assignedBijlage}
                  bijlagen={bijlagen}
                  onCancel={onCancel}
                  onOpen={onOpen}
                />
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}
