import React, { FC } from 'react'

import { Dialog } from '@mui/material'

import { Cancel } from './dialogs/Cancel'
import { Create } from './dialogs/Create'
import { Edit } from './dialogs/Edit'
import { Opleveren } from './dialogs/Opleveren'
import { Review } from './dialogs/Review'
import { Upload } from './dialogs/Upload'

interface Props {
  open: boolean
  data: any
  onClose: () => void
}

export const BijlagenDialog: FC<Props> = ({ open, data, onClose }) => {
  const { type } = data

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      {type === 'submit' && <Opleveren data={data} onClose={onClose} />}
      {type === 'create' && <Create data={data} onClose={onClose} />}
      {type === 'cancel' && <Cancel data={data} onClose={onClose} />}
      {type === 'upload' && <Upload data={data} onClose={onClose} />}
      {type === 'review' && <Review data={data} onClose={onClose} />}
      {type === 'edit' && <Edit data={data} onClose={onClose} />}
    </Dialog>
  )
}
