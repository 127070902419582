import React, { useState } from 'react'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import { getISOWeek, getISOWeeksInYear } from 'date-fns'

import {
  beginningOfYearDateForISOWeek,
  formatWeekYearToISOWeek,
  parseISO,
} from '@util/date-util'

import { editAssignedBijlageForEntity } from '../../state'
import type { AssignedBijlage } from '../../types'

interface Props {
  data: {
    bijlage: AssignedBijlage
  }
  onClose: () => void
}

const MAX_WEEKS_IN_YEAR = 53
const WEEK_NUMBER_53 = 53

export const Edit: FC<Props> = ({ data, onClose }) => {
  const dispatch = useDispatch()

  const { bijlage } = data
  const { dueWeek } = bijlage

  const [jaar, setJaar] = useState<Date | null>(
    dueWeek ? beginningOfYearDateForISOWeek(dueWeek) : null,
  )
  const [week, setWeek] = useState<number | undefined>(
    dueWeek ? getISOWeek(parseISO(dueWeek)) : undefined,
  )
  const [errors, setErrors] = useState({})

  const validate = () => {
    let dueWeekError = !week || week > MAX_WEEKS_IN_YEAR || week < 1
    const dueJaarError = !jaar || jaar.getFullYear() < new Date().getFullYear()

    // Validate week 53
    if (!dueWeekError && !dueJaarError && week === WEEK_NUMBER_53) {
      dueWeekError = getISOWeeksInYear(jaar) !== MAX_WEEKS_IN_YEAR
    }

    setErrors({
      dueWeekError,
      dueJaarError,
    })

    return !(dueWeekError || dueJaarError)
  }
  const onClick = () => {
    if (!validate()) return

    dispatch(
      editAssignedBijlageForEntity({
        entity: bijlage.entity,
        id: bijlage._id,
        dueWeek: formatWeekYearToISOWeek(jaar, week),
        status: bijlage.status,
      }),
    )
  }

  const handleDelete = () => {
    dispatch(
      editAssignedBijlageForEntity({
        entity: bijlage.entity,
        id: bijlage._id,
        dueWeek: null,
        status: bijlage.status,
      }),
    )
  }

  return (
    <>
      <DialogTitle>{`Aanpassen ${bijlage.documentsoort} ${
        bijlage.discipline || ''
      }`}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', pb: 1 }}>
          <DatePicker
            views={['year']}
            disablePast
            label="Jaar"
            value={jaar}
            onChange={setJaar as any}
            openTo="year"
            slotProps={{
              field: { clearable: true },
              textField: {
                error: Boolean(errors['dueJaarError']),
                variant: 'standard',
                sx: { width: '33%', pr: 1 },
              },
            }}
          />
          <TextField
            InputProps={{
              inputProps: {
                min: 1,
                max: 52,
                'data-testid': 'dueWeek-field',
              },
            }}
            sx={{ width: '33%', pr: 1 }}
            variant="standard"
            type="number"
            value={week || ''}
            label={'Week'}
            onChange={(e) => setWeek(Number(e.target.value))}
            error={errors['dueWeekError']}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {dueWeek && (
          <Button onClick={handleDelete} color="error">
            Verwijder planning
          </Button>
        )}

        <Button onClick={onClose}>Annuleren</Button>
        <Button onClick={onClick}>Opslaan</Button>
      </DialogActions>
    </>
  )
}
