import React, { FormEventHandler, Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'

import SaveIcon from '@mui/icons-material/Save'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import type { DisciplineVerzameling } from '@models/DisciplineVerzameling'

import { saveEvent } from '../../state'
import type { OptionsDialogData } from '../../types'

const dateFormat = 'dd-MM-yyyy'

type Props = {
  data: OptionsDialogData
  handleClose: () => void
}

export const TechnischGereed = ({ data: { project }, handleClose }: Props) => {
  const dispatch = useDispatch()

  const [disciplines, setDisciplines] = useState(
    project.disciplineVerzamelingen,
  )

  const handleDisciplineUpdate = ({
    disciplineCode,
    netbeheerderCode,
    technischGereed,
  }: DisciplineVerzameling) => {
    setDisciplines((disciplines) =>
      disciplines.map((d) => {
        if (
          d.disciplineCode === disciplineCode &&
          d.netbeheerderCode === netbeheerderCode
        ) {
          return {
            ...d,
            technischGereed,
          }
        }

        return d
      }),
    )
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    dispatch(
      saveEvent({
        id: project.aanvraagID,
        type: 'technisch_gereed',
        source: 'manual',
        data: {
          disciplineVerzamelingen: disciplines,
        },
      }),
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogTitle>Technisch gereed melden</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {disciplines.map((discipline) => (
            <Fragment
              key={discipline.disciplineCode + discipline.netbeheerderCode}
            >
              <Grid item xs={5}>
                <FormControlLabel
                  label={`${discipline.disciplineLabel} - ${discipline.netbeheerderLabel}`}
                  control={
                    <Switch
                      checked={Boolean(discipline.technischGereed)}
                      onChange={(_, checked) => {
                        handleDisciplineUpdate({
                          ...discipline,
                          technischGereed: checked ? new Date() : undefined,
                        })
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={7} sx={{ minHeight: '56px' }}>
                {discipline.technischGereed && (
                  <DatePicker
                    format={dateFormat}
                    label="Technisch gereed datum"
                    value={discipline.technischGereed}
                    onChange={(value) => {
                      if (value) {
                        handleDisciplineUpdate({
                          ...discipline,
                          technischGereed: new Date(value),
                        })
                      }
                    }}
                    displayWeekNumber
                  />
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Annuleren
        </Button>

        <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
          Opslaan
        </Button>
      </DialogActions>
    </form>
  )
}
