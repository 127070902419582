import React, { FC } from 'react'

import type { Werklijst } from '../../types'
import { DashboardCard } from '../DashboardCard'

export interface Props {
  werklijst: Werklijst
  onClickTile: (werklijst: Werklijst) => void
  subLabel?: string
}

export const Tile: FC<Props> = ({
  werklijst,
  onClickTile,
  subLabel,
}: Props) => {
  const { label, count } = werklijst

  return (
    <DashboardCard
      onClick={() => onClickTile(werklijst)}
      label={label}
      subLabel={subLabel}
      count={count}
    />
  )
}
