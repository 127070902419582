export const activiteittypes = [
  'Afspraak',
  'Bodemonderzoek aanvragen',
  'Klantcontact',
  'Schouw inplannen',
  'Vergunning aanvragen',
  'Wacht op klant',
  'Inplannen overdrachtsoverleg',
  'Vooroverleg uitvoeren',
  'Voorcalculatie aanleveren',
]

export const activiteittypesOptions = activiteittypes.map((s) => ({
  code: s,
  label: s,
}))

export const bijlageStatusTranslations = {
  review: 'Ter beoordeling',
  uploaded: 'Afgekeurd',
  assigned: 'Toegewezen',
}
