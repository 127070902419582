import React, { FC } from 'react'

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { ProductIcon } from '@components/DisciplineIcon'
import StyledCard from '@components/StyledCard'
import { formatDate } from '@util/date-util'

import type { HoofdleidingProject } from '../../projecten/types'

export { DisciplineVerzamelingen }

const headerCells = [
  { id: 'disciplineCode', numeric: false, label: 'Discipline' },
  { id: 'netbeheerderCode', numeric: false, label: 'Netbeheerder' },
  { id: 'technisch_gereed', numeric: false, label: 'Technisch gereed' },
] as const

type Props = {
  project: HoofdleidingProject
}

const DisciplineVerzamelingen: FC<Props> = ({ project }) => {
  const { disciplineVerzamelingen = [] } = project

  return (
    <StyledCard title="Disciplines">
      <Table sx={{ padding: 0, backgroundColor: '#f7f7f7' }}>
        <TableHead>
          <TableRow>
            {headerCells.map((headerCell) => (
              <TableCell key={headerCell.id}>{headerCell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {disciplineVerzamelingen.map((dv) => (
            <TableRow key={dv.disciplineCode}>
              <TableCell>
                <Grid container direction="row" alignItems="center">
                  <ProductIcon
                    product={{
                      disciplineCode: dv.disciplineCode,
                      disciplineLabel: dv.disciplineLabel,
                      netbeheerderCode: dv.netbeheerderCode,
                    }}
                  />
                  <Typography style={{ fontSize: 'inherit' }}>
                    {dv.disciplineLabel}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell>{dv.netbeheerderLabel}</TableCell>
              <TableCell>
                {dv.technischGereed && formatDate(dv.technischGereed)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledCard>
  )
}
