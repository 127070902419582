import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { useProgress } from '@hooks/useProgress'

import type { RootState } from '../../../src/store'
import { apiError, success } from '../../alerts/state'
import { request, requestS3 } from '../../gateways/api'

import type { EntityBijlagenState } from './types'

const initialState: EntityBijlagenState = {
  assignedBijlagen: [],
  bijlagen: [],
  bijlageTemplates: [],
  bijlagenDialog: {
    open: false,
    data: {},
  },
}

export const ENTITY_PROJECTDOSSIER = 'projectdossier'
export const ENTITY_OPDRACHT = 'opdracht'
export const ENTITY_DEELNEMER_OPDRACHT = 'opdracht/deelnemer'

export const fetchBijlagenForEntity = createAsyncThunk(
  'fetch/entity/bijlagen',
  async ({ id, type }: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/${type}/${id}/bijlage`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen bijlagen', error))

      throw error
    }
  },
)

export const fetchAssignedBijlagenForEntity = createAsyncThunk(
  'fetch/entity/assignedbijlagen',
  async ({ id, type }: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/${type}/${id}/bijlageassigned`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen toegewezen bijlagen', error))

      throw error
    }
  },
)

export const opleverenAssignedBijlageForEntity = createAsyncThunk(
  'complete/entity/assignedbijlage',
  async ({ entity, id, assignedTo }: any, { dispatch, rejectWithValue }) => {
    const { id: entityId, type } = entity
    const url = `/rest/${type}/${entityId}/bijlageassigned/${id}`
    const data: { status: string; assignedTo?: string } = { status: 'done' }

    if (assignedTo) {
      data.status = 'review'
      data.assignedTo = assignedTo
    }

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data,
      })

      dispatch(
        success(
          `Bijlage successvol opgeleverd${assignedTo ? ' voor review' : ''}`,
        ),
      )
      dispatch(fetchAssignedBijlagenForEntity(entity))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij opleveren bijlage', error))

      return rejectWithValue({ error })
    }
  },
)

export const reviewAssignedBijlageForEntity = createAsyncThunk(
  'complete/entity/assignedbijlage',
  async (
    { entity, id, approved, reden }: any,
    { dispatch, rejectWithValue },
  ) => {
    const { id: entityId, type } = entity
    const url = `/rest/${type}/${entityId}/bijlageassigned/${id}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: {
          status: approved ? 'done' : 'uploaded',
          reden,
        },
      })

      dispatch(
        success(`Bijlage succesvol ${approved ? 'goedgekeurd' : 'afgekeurd'}`),
      )
      dispatch(fetchAssignedBijlagenForEntity(entity))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij beoordelen bijlage', error))

      return rejectWithValue({ error })
    }
  },
)

export const editAssignedBijlageForEntity = createAsyncThunk(
  'edit/entity/assignedbijlage',
  async (
    { entity, id, dueWeek, status }: any,
    { dispatch, rejectWithValue },
  ) => {
    const { id: entityId, type } = entity
    const url = `/rest/${type}/${entityId}/bijlageassigned/${id}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: {
          dueWeek,
          status,
        },
      })

      dispatch(success('Bijlage succesvol aangepast'))
      dispatch(fetchAssignedBijlagenForEntity(entity))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij aanpassen bijlage', error))

      return rejectWithValue({ error })
    }
  },
)

export const uploadBijlagenForEntity = createAsyncThunk<
  any,
  {
    entity: any
    selectedFiles: any[]
    progress: ReturnType<typeof useProgress>
    id?: string
    opleveren?: boolean
    assignedTo?: string
  }
>(
  'upload/entity/bijlagen',
  async (
    { entity, selectedFiles, progress, id, opleveren, assignedTo },
    { dispatch, rejectWithValue },
  ) => {
    const STATUS_UPLOADED = 'uploaded'
    const { type, id: entityId } = entity

    const url = `/rest/${type}/${entityId}${
      id ? `/bijlageassigned/${id}` : ''
    }/bijlage`

    progress.set({
      current: progress.current,
      error: progress.error,
      total: progress.total,
    })

    try {
      for (const selectedFile of selectedFiles) {
        const data = { naam: selectedFile.name }

        const saveResult = await request({ url, method: 'POST', data })

        const { url: uploadUrl } = saveResult.uploadData

        await requestS3({ url: uploadUrl, method: 'PUT', data: selectedFile })

        const { _id } = saveResult.databaseData

        await request({
          url: `${url}/${_id}`,
          method: 'PATCH',
          data: { status: STATUS_UPLOADED },
        })

        if (type === ENTITY_OPDRACHT) {
          await request({
            url: `${url}/${_id}`,
            method: 'PATCH',
            data: { status: 'review' },
          })
        }

        dispatch(fetchBijlagenForEntity(entity))

        progress.bump()
      }

      if (id) {
        dispatch(fetchAssignedBijlagenForEntity(entity))

        if (opleveren) {
          dispatch(
            opleverenAssignedBijlageForEntity({ entity, id, assignedTo }),
          )
        }
      }

      progress.clear()
      dispatch(success('Bijlagen successvol toegevoegd'))
    } catch (error: any) {
      dispatch(apiError('Fout bij opslaan bijlage', error))
      progress.fail()

      if (id) {
        dispatch(fetchAssignedBijlagenForEntity(entity))
      }

      return rejectWithValue({ error })
    }
  },
)

export const createAssignedBijlageForEntity = createAsyncThunk(
  'create/entity/assignedbijlage',
  async ({ entity, bijlageInfo }: any, { dispatch, rejectWithValue }) => {
    const { type, id } = entity
    const url = `/rest/${type}/${id}/bijlageassigned`

    try {
      const result = await request({ url, method: 'POST', data: bijlageInfo })

      dispatch(success('Bijlage successvol aangemaakt'))
      dispatch(fetchAssignedBijlagenForEntity(entity))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij aanmaken bijlage', error))
      return rejectWithValue({ error })
    }
  },
)

export const fetchBijlageTemplatesForEntity = createAsyncThunk(
  'fetch/entity/bijlagetemplates',
  async ({ id, type }: any, { dispatch }) => {
    try {
      const result = await request({
        url: `/rest/${type}/${id}/bijlagetemplate`,
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen bijlage templates', error))

      throw error
    }
  },
)

export const openBijlageForEntity = createAsyncThunk(
  'open/bijlage',
  async ({ entity, bijlage }: any, { dispatch, rejectWithValue }) => {
    const { id: entityId, type } = entity
    const { _id } = bijlage

    try {
      const result = await request({
        url: `/rest/${type}/${entityId}/bijlage/${_id}`,
        method: 'GET',
      })

      const { url: downloadUrl } = result.downloadData

      window.open(downloadUrl, '_blank')
    } catch (error: any) {
      dispatch(apiError('Fout bij openen bijlage', error))

      return rejectWithValue({ error })
    }
  },
)

export const cancelBijlageForEntity = createAsyncThunk(
  'cancel/bijlage',
  async ({ entity, id, isAssigned }: any, { dispatch, rejectWithValue }) => {
    const { id: entityId, type } = entity
    const url = `/rest/${type}/${entityId}/bijlage${
      isAssigned ? 'assigned' : ''
    }/${id}`

    try {
      const result = await request({
        url,
        method: 'PATCH',
        data: { status: 'cancelled' },
      })

      dispatch(success('Bijlage successvol geannuleerd'))
      dispatch(fetchAssignedBijlagenForEntity(entity))
      dispatch(fetchBijlagenForEntity(entity))

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij annuleren bijlage', error))

      return rejectWithValue({ error })
    }
  },
)

const slice = createSlice({
  name: 'entityBijlagen',
  initialState,
  reducers: {
    openBijlagenDialog: (state, action) => {
      state.bijlagenDialog.open = true
      state.bijlagenDialog.data = action.payload
    },
    closeBijlagenDialog: (state) => {
      state.bijlagenDialog.open = false
      state.bijlagenDialog.data = {}
    },
    clearEntityBijlagen: (state) => {
      state.assignedBijlagen = initialState.assignedBijlagen
      state.bijlagen = initialState.bijlagen
      state.bijlageTemplates = initialState.bijlageTemplates
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBijlagenForEntity.fulfilled, (state, action) => {
        state.bijlagen = action.payload
      })
      .addCase(fetchBijlagenForEntity.rejected, (state) => {
        state.bijlagen = initialState.bijlagen
      })
      .addCase(uploadBijlagenForEntity.fulfilled, (state) => {
        state.bijlagenDialog.open = false
        state.bijlagenDialog.data = {}
      })
      .addCase(createAssignedBijlageForEntity.fulfilled, (state) => {
        state.bijlagenDialog.open = false
        state.bijlagenDialog.data = {}
      })
      .addCase(fetchAssignedBijlagenForEntity.fulfilled, (state, action) => {
        state.assignedBijlagen = action.payload
      })
      .addCase(fetchAssignedBijlagenForEntity.rejected, (state) => {
        state.assignedBijlagen = initialState.assignedBijlagen
      })
      .addCase(fetchBijlageTemplatesForEntity.fulfilled, (state, action) => {
        state.bijlageTemplates = action.payload
      })
      .addCase(fetchBijlageTemplatesForEntity.rejected, (state) => {
        state.bijlageTemplates = []
      })
      .addCase(cancelBijlageForEntity.fulfilled, (state) => {
        state.bijlagenDialog.open = false
        state.bijlagenDialog.data = {}
      })
      .addCase(opleverenAssignedBijlageForEntity.fulfilled, (state) => {
        state.bijlagenDialog.open = false
        state.bijlagenDialog.data = {}
      })
      .addCase(editAssignedBijlageForEntity.fulfilled, (state) => {
        state.bijlagenDialog.open = false
        state.bijlagenDialog.data = {}
      })
  },
})

export const getBijlagenForEntity = (state: RootState) =>
  state.entityBijlagen.bijlagen
export const getBijlageTemplatesForEntity = (state: RootState) =>
  state.entityBijlagen.bijlageTemplates
export const getAssignedBijlagenForEntity = (state: RootState) =>
  state.entityBijlagen.assignedBijlagen
export const isBijlagenDialogOpen = (state: RootState) =>
  state.entityBijlagen.bijlagenDialog.open
export const getBijlagenDialogData = (state: RootState) =>
  state.entityBijlagen.bijlagenDialog.data

export const { clearEntityBijlagen, closeBijlagenDialog, openBijlagenDialog } =
  slice.actions
export const { reducer } = slice
